import React from 'react'
import { Image, View } from 'react-native'
import { useUserContext } from '../contexts/UserContext'

export default function AppbarImage() {
  const { darkMode } = useUserContext()

  return (
    <View style={{ flexDirection: 'row' }}>
      <Image
        source={
          darkMode === 'dark'
            ? require('../../assets/images/header-logo-no-subtitle-dark.png')
            : require('../../assets/images/header-logo-no-subtitle.png')
        }
        resizeMode="contain"
        style={{
          height: 60,
          width: '100%'
        }}
      />
    </View>
  )
}
