import React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'

import Welcome from '../screens/Welcome'
import CRUD from '../screens/CRUD'
import Event from '../screens/Event'
import UpdatePayment from '../screens/UpdatePayment'
import EditProfile from '../screens/EditProfile'
import CheckOut from '../screens/CheckOut'
import Results from '../screens/Results'
import ConfirmGift from '../screens/ConfirmGift'
import Training from '../screens/Training'

import AppHeader from '../navigation/AppHeader'

const Stack = createStackNavigator()
export default function MainStack() {
  return (
    <Stack.Navigator
      headerMode="screen"
      screenOptions={{
        header: ({ scene, previous, navigation }) => (
          <AppHeader
            scene={scene}
            previous={previous}
            navigation={navigation}
          />
        )
      }}>
      <Stack.Screen name="Welcome" component={Welcome} />
      <Stack.Screen name="CRUD" component={CRUD} />
      <Stack.Screen
        name="Event"
        component={Event}
        options={({ route }) => ({
          title: route.params.name,
          website: route.params.website
        })}
      />
      <Stack.Screen
        name="EditProfile"
        component={EditProfile}
        options={{ title: 'Edit Profile' }}
      />
      <Stack.Screen
        name="UpdatePayment"
        component={UpdatePayment}
        options={{ title: 'Update Payment' }}
      />
      <Stack.Screen
        name="CheckOut"
        component={CheckOut}
        options={{ title: 'Check Out' }}
      />
      <Stack.Screen name="Results" component={Results} />
      <Stack.Screen
        name="ConfirmGift"
        component={ConfirmGift}
        options={{ title: 'Confirm Gift' }}
      />
      <Stack.Screen
        name="Training"
        component={Training}
        options={{ title: 'Generate Training Data' }}
      />
    </Stack.Navigator>
  )
}
