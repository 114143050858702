import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ScrollView, View, StyleSheet, Platform } from 'react-native'
import { Paragraph, Text, Button } from 'react-native-paper'
import { DeviceMotion } from 'expo-sensors'

import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { createLabeledSensorData } from '../graphql/mutations'

import { trainGestureModel, predictGesture } from '../utils/gesture-model'
import { predictGestureONNX } from '../utils/gesture-model-onnx'

export const PREDICT_LABEL = 0
export const RAISED = 1
export const NOT_RAISED = 2

export default function Training({ navigation }) {
  const [trained, setTrained] = useState(false)
  const [onnxMode, setOnnxMode] = useState(false)
  const [sampleLabel, setSampleLabel] = useState(null)
  const [samples, setSamples] = useState([])
  const [currentSample, setCurrentSample] = useState([])
  const [phoneRaisedMsg, setPhoneRaisedMsg] = useState(null)
  const [totalSaved, setTotalSaved] = useState(0)

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      height: '100%',
      marginBottom: 25
    },
    samplesScroll: {
      width: '100%',
      marginBottom: 8
    },
    samplesContainer: {
      padding: 20
    },
    sampleText: {
      fontFamily: Platform.OS === 'ios' ? 'Courier' : 'monospace',
      fontSize: 10
    },
    instructions: {
      fontSize: 12,
      lineHeight: 12,
      marginBottom: 12
    },
    button: {
      margin: 8
    },
    buttonGroup: {
      flexDirection: 'row'
    }
  })

  // Start our DeviceMotion monitoring once... only process the data if collecting.
  useEffect(() => {
    DeviceMotion.setUpdateInterval(1000 / 100)
    const subscription = DeviceMotion.addListener(async data => {
      if (sampleLabel != null) {
        if (currentSample.length >= 30) {
          if (sampleLabel == PREDICT_LABEL) {
            const msg = await calculatePhoneRaisedMsg(currentSample)
            setPhoneRaisedMsg(msg)
            setCurrentSample([])
          } else {
            // Concat our sample label to the end of the current sample and save in our array of samples
            setSamples(samples.concat([currentSample.concat([sampleLabel])]))
            setCurrentSample([])
          }
          setSampleLabel(null)
        } else {
          setCurrentSample(
            currentSample.concat([
              data.rotation.alpha,
              data.rotation.beta,
              data.rotation.gamma
            ])
          )
        }
      }
    })
    return () => subscription.remove()
  }, [sampleLabel, currentSample, setCurrentSample, setPhoneRaisedMsg])

  const saveSamples = () => {
    samples.forEach(sample => {
      const variables = {
        alpha1: sample[0],
        beta1: sample[1],
        gamma1: sample[2],
        alpha2: sample[3],
        beta2: sample[4],
        gamma2: sample[5],
        alpha3: sample[6],
        beta3: sample[7],
        gamma3: sample[8],
        alpha4: sample[9],
        beta4: sample[10],
        gamma4: sample[11],
        alpha5: sample[12],
        beta5: sample[13],
        gamma5: sample[14],
        alpha6: sample[15],
        beta6: sample[16],
        gamma6: sample[17],
        alpha7: sample[18],
        beta7: sample[19],
        gamma7: sample[20],
        alpha8: sample[21],
        beta8: sample[22],
        gamma8: sample[23],
        alpha9: sample[24],
        beta9: sample[25],
        gamma9: sample[26],
        alpha10: sample[27],
        beta10: sample[28],
        gamma10: sample[29],
        label: sample[30]
      }
      API.graphql(
        graphqlOperation(createLabeledSensorData, { input: variables })
      )
    })
    setTotalSaved(totalSaved + samples.length)
    setSamples([])
  }

  const calculatePhoneRaisedMsg = async sample => {
    let raised = false
    if (onnxMode) {
      const preds = await predictGestureONNX(sample)
      raised = preds[0] > preds[1]
    } else {
      const raised = predictGesture(sample)[0] == 0
    }
    return raised ? 'You RAISED your phone!' : 'You DID NOT raise your phone.'
  }

  return (
    <View style={styles.container}>
      <ScrollView
        style={styles.samplesScroll}
        contentContainerStyle={styles.samplesContainer}>
        {!phoneRaisedMsg && samples.length == 0 && (
          <>
            {totalSaved > 0 && (
              <Paragraph style={styles.instructions}>
                You have created{' '}
                <Text style={{ fontWeight: 'bold' }}>{totalSaved}</Text>{' '}
                samples!
              </Paragraph>
            )}
            {totalSaved == 0 && (
              <Paragraph style={styles.instructions}>
                Lets create some training data!
              </Paragraph>
            )}
            <Paragraph style={styles.instructions}>
              To create training data click one of the buttons below; "RAISED"
              or "NOT RAISED".
            </Paragraph>
            <Paragraph style={styles.instructions}>
              After clicking the button we will collect gyroscope and
              accelerometer data for ONE SECOND.
            </Paragraph>
            <Paragraph style={styles.instructions}>
              If you clicked the "RAISED" button then you have one second to
              turn your phone away from you and raise it like a paddle (as if
              you were showing an auctioneer).
            </Paragraph>
            <Paragraph style={styles.instructions}>
              If you clicked the "NOT RAISED" button then do whatever you'd like
              that isn't the action described for "RAISED". Show your phone to
              yourself, or the person to your left or right, or flip you phone
              on the table, or do nothing. Just try to make sure the action is
              distinct from the "RAISED" action.
            </Paragraph>
            <Paragraph style={styles.instructions}>
              <Text style={{ fontWeight: 'bold' }}>WHEN YOU'RE DONE</Text>...
              You should have a bunch of numbers on the screen. Click the "SAVE
              SAMPLES" button to push your data up to the server. If you'd
              rather discard your work click "RESET"
            </Paragraph>
          </>
        )}
        {!phoneRaisedMsg && samples.length > 0 && (
          <Text style={styles.sampleText}>{JSON.stringify(samples)}</Text>
        )}
        {phoneRaisedMsg && (
          <Text style={styles.sampleText}>{phoneRaisedMsg}</Text>
        )}
      </ScrollView>
      <View style={styles.buttonGroup}>
        <Button
          style={styles.button}
          mode="contained"
          onPress={() => setSampleLabel(RAISED)}
          disabled={sampleLabel != null}>
          Raised
        </Button>
        <Button
          style={styles.button}
          mode="contained"
          onPress={() => setSampleLabel(NOT_RAISED)}
          disabled={sampleLabel != null}>
          NOT raised
        </Button>
      </View>
      <View style={styles.buttonGroup}>
        <Button
          style={styles.button}
          mode="outlined"
          onPress={saveSamples}
          disabled={sampleLabel != null}>
          Save Samples
        </Button>
        <Button
          style={styles.button}
          mode="outlined"
          onPress={() => {
            setTrained(false)
            setTotalSaved(0)
            setCurrentSample([])
            setSamples([])
            setPhoneRaisedMsg(null)
          }}
          disabled={sampleLabel != null}>
          Reset
        </Button>
      </View>
      <View style={styles.buttonGroup}>
        <Button
          style={styles.button}
          mode="outlined"
          onPress={async () => {
            await trainGestureModel()
            setTrained(true)
          }}>
          Train
        </Button>
        <Button
          style={styles.button}
          mode="outlined"
          onPress={() => {
            setOnnxMode(true)
            setSampleLabel(PREDICT_LABEL)
          }}
          disabled={!trained}>
          Test
        </Button>
      </View>
      <View style={styles.buttonGroup}>
        <Button
          style={styles.button}
          mode="outlined"
          onPress={() => {
            setOnnxMode(true)
            setSampleLabel(PREDICT_LABEL)
          }}>
          Test ONNX
        </Button>
      </View>
    </View>
  )
}
