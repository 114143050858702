import React from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { Button } from 'react-native-paper'

export default function Results({ navigation }) {
  return (
    <View style={styles.container}>
      <Text>Results</Text>

      <Button
        mode="contained"
        onPress={() => {
          navigation.navigate('Welcome')
        }}>
        Restart
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
