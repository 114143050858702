import React, { useEffect, useState } from 'react'
import { Dimensions, ScrollView, StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import {
  Button,
  Text,
  Title,
  Paragraph,
  TextInput,
  Card,
  FAB,
  useTheme
} from 'react-native-paper'
import { MaterialIcons } from '@expo/vector-icons'
import { useForm } from 'react-hook-form'
import DraggableFlatList from 'react-native-draggable-flatlist'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import md5 from 'crypto-js/md5'

import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { createEvent, updateEvent } from '../graphql/mutations'
import { listEvents } from '../graphql/queries'

import { formatCurrency } from '../utils/text-formatting'
import EventSelector from '../components/EventSelector'
import AddOrEditNeedModal from '../components/AddOrEditNeedModal'
import { useUserContext } from '../contexts/UserContext'
import { useImageMapContext } from '../contexts/ImageMapContext'

export default function EventCRUD({ navigation }) {
  const theme = useTheme()

  const NEED_CAROUSEL_WIDTH = Dimensions.get('window').width - 40
  const NEED_WIDTH = Math.round(NEED_CAROUSEL_WIDTH * 0.7)

  const { user, darkMode } = useUserContext()
  const { resolveUris, imageUrlMap } = useImageMapContext()
  const [pickerSelection, setPickerSelection] = useState(null)
  const [needModalVisible, setNeedModalVisible] = useState(false)
  const [editNeedIndex, setEditNeedIndex] = useState(null)

  const styles = StyleSheet.create({
    container: {
      flex: 1
    },
    contentContainer: {
      padding: 20
    },
    field: {
      marginBottom: 5,
      marginTop: 5
    },
    error: {
      fontSize: 12,
      color: theme.colors.accent
    },
    needsCarousel: {
      marginBottom: 10
    },
    needCard: {
      width: NEED_WIDTH,
      marginLeft: 5,
      marginRight: 5
    },
    needTitle: {
      fontSize: 18,
      lineHeight: 18,
      paddingTop: 8,
      paddingBottom: 4
    },
    needAmount: {
      position: 'absolute',
      right: 0,
      top: -45,
      padding: 8,
      backgroundColor: '#fff',
      color: theme.colors.accent,
      fontSize: 16,
      fontWeight: 'bold'
    },
    needFab: {
      position: 'absolute',
      top: '50%',
      right: -15
    },
    inputIOS: {
      fontSize: 16,
      paddingVertical: 12,
      paddingHorizontal: 10,
      borderWidth: 1,
      borderColor: 'gray',
      borderRadius: 4,
      color: darkMode === 'dark' ? 'white' : 'black',
      paddingRight: 30, // to ensure the text is never behind the icon
      marginBottom: 20
    },
    inputAndroid: {
      fontSize: 16,
      paddingHorizontal: 10,
      paddingVertical: 8,
      borderWidth: 0.5,
      borderColor: 'gray',
      borderRadius: 8,
      color: darkMode === 'dark' ? 'white' : 'black',
      paddingRight: 30, // to ensure the text is never behind the icon
      marginBottom: 20
    }
  })

  const {
    register,
    errors,
    handleSubmit,
    watch,
    setValue,
    formState,
    reset
  } = useForm()

  useEffect(() => {
    //  Register fields for the form and setup validations
    register('id')
    register('name', {
      validate: input =>
        (input && input.length >= 10) ||
        'Event name must be at least 10 characters'
    })
    register('lat', {
      validate: input =>
        !input || !isNaN(parseFloat(input)) || 'Latitude must be a number'
    })
    register('lng', {
      validate: input =>
        !input || !isNaN(parseFloat(input)) || 'Longitude must be a number'
    })
    register('website', {
      validate: input =>
        !input || input.match(/^https*:\/\//) || 'Website must be a URL'
    })
    register('needs')
    register('dirty') // TODO: See comment below
  }, [register])

  const formValues = watch()

  const onSubmit = () => {
    ;(async function () {
      let op = pickerSelection ? updateEvent : createEvent

      const variables = {
        id: formValues.id,
        name: formValues.name,
        website: formValues.website
      }

      //  latitude and longitude are floats in the API
      if (formValues.lat && formValues.lat != '') {
        variables.lat = parseFloat(formValues.lat)
      }
      if (formValues.lng && formValues.lat != '') {
        variables.lng = parseFloat(formValues.lng)
      }

      // JSON.stringify the needs
      if (formValues?.needs.length > 0) {
        variables.needs = formValues.needs.map(need => JSON.stringify(need))
      }

      await API.graphql(graphqlOperation(op, { input: variables }))

      // Reset the form and update the picker
      reset({})
      setPickerSelection(null)
    })()
  }

  //  TODO: figure out how to use field arrays: https://react-hook-form.com/api/#useFieldArray
  //  instead of cheating to get the form to go dirty when needs are changed
  const updateNeeds = needs => {
    setEditNeedIndex(null)
    setValue('needs', needs)
    setValue('dirty', true)
  }

  const renderNeed = ({ item, index, drag, isActive }) => {
    //  Make sure that we've looked up the URL for this image
    resolveUris([item.image])
    const imageSource = imageUrlMap[item.image]
      ? { uri: imageUrlMap[item.image] }
      : null
    return (
      <Card style={styles.needCard} onLongPress={drag}>
        <Card.Cover source={imageSource} />
        <Card.Content>
          <Text style={styles.needAmount}>{formatCurrency(item.amount)}</Text>
          <Text style={styles.needTitle}>{item.name}</Text>
          <Text numberOfLines={3}>{item.description}</Text>
        </Card.Content>
        <Card.Actions>
          <Button
            onPress={() => {
              setEditNeedIndex(index)
              setNeedModalVisible(true)
            }}>
            Edit
          </Button>
          <Button
            onPress={() => {
              const newNeeds = formValues.needs
              newNeeds.splice(index, 1)
              updateNeeds(newNeeds)
            }}>
            Delete
          </Button>
        </Card.Actions>
      </Card>
    )
  }

  return (
    <KeyboardAwareScrollView
      resetScrollToCoords={{ x: 0, y: 0 }}
      contentContainerStyle={styles.contentContainer}
      scrollEnabled={true}
      viewIsInsideTabBar={true}>
      <View>
        <Text>Select an Event to Edit</Text>
        <EventSelector
          style={styles.eventChooser}
          filter={{ filter: { owner: { eq: user.username } } }}
          onValueChange={value => {
            setPickerSelection(value)
            if (value) {
              // Pre-resolve all of our images to URIs in bulk (it's faster than one at a time)
              resolveUris(value.needs.map(need => need.image))

              // Initialize our forms
              reset({
                id: value.id,
                name: value.name,
                lat: value.lat ? value.lat.toString() : null,
                lng: value.lng ? value.lng.toString() : null,
                website: value.website,
                needs: value.needs
              })
            } else {
              reset({})
            }
          }}
        />
        <Text>...or Create a New Event</Text>
        <TextInput
          style={styles.field}
          label="Event Name"
          onChangeText={text => setValue('name', text)}
          returnKeyType="done"
          value={formValues.name}
        />
        {errors.name && <Text style={styles.error}>{errors.name.message}</Text>}
        <TextInput
          style={styles.field}
          label="Latitude"
          onChangeText={text => setValue('lat', text)}
          keyboardType={'numbers-and-punctuation'}
          returnKeyType="done"
          value={formValues.lat}
        />
        {errors.lat && <Text style={styles.error}>{errors.lat.message}</Text>}
        <TextInput
          style={styles.field}
          label="Longitude"
          onChangeText={text => setValue('lng', text)}
          keyboardType={'numbers-and-punctuation'}
          returnKeyType="done"
          value={formValues.lng}
        />
        {errors.lng && <Text style={styles.error}>{errors.lng.message}</Text>}
        <TextInput
          style={styles.field}
          label="Website"
          onChangeText={text => setValue('website', text)}
          returnKeyType="done"
          value={formValues.website}
          autoCompleteType={'off'}
          autoCapitalize={'none'}
          autoCorrect={false}
        />
        {errors.website && (
          <Text style={styles.error}>{errors.website.message}</Text>
        )}
        <ActionSheetProvider>
          <View style={styles.needsCarousel}>
            <AddOrEditNeedModal
              needIndex={editNeedIndex}
              visible={needModalVisible}
              setVisible={setNeedModalVisible}
              eventId={formValues.id}
              eventFormValues={formValues}
              updateNeeds={updateNeeds}
            />
            {formValues.needs && (
              <>
                <DraggableFlatList
                  horizontal={true}
                  data={formValues.needs}
                  renderItem={renderNeed}
                  keyExtractor={(item, index) =>
                    md5(JSON.stringify(item)).toString()
                  }
                  onDragEnd={({ data }) => updateNeeds(data)}
                  style={styles.needCarousel}
                />
                <FAB
                  style={styles.needFab}
                  small
                  icon="plus"
                  onPress={() => setNeedModalVisible(true)}
                />
              </>
            )}
            {!formValues.needs && (
              <Card style={{ width: '100%' }}>
                <Card.Content>
                  <Text numberOfLines={3}>
                    Create your event and then you'll be able to add needs.
                  </Text>
                </Card.Content>
              </Card>
            )}
          </View>
        </ActionSheetProvider>
        <Button
          mode="contained"
          disabled={!formState.dirty && !formValues.dirty}
          onPress={handleSubmit(onSubmit)}>
          Save
        </Button>
      </View>
    </KeyboardAwareScrollView>
  )
}
