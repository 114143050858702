import { decode, encode } from 'base-64'

// React Native doesn't have FileReader.readAsArrayBuffer.  This is copied from here:
// https://github.com/facebook/react-native/issues/21209#issuecomment-495294672
FileReader.prototype.readAsArrayBuffer = function (blob) {
  if (this.readyState === this.LOADING) throw new Error('InvalidStateError')
  this._setReadyState(this.LOADING)
  this._result = null
  this._error = null
  const fr = new FileReader()
  fr.onloadend = () => {
    const content = decode(fr.result.split(';base64,')[1])
    const buffer = new ArrayBuffer(content.length)
    const view = new Uint8Array(buffer)
    view.set(Array.from(content).map(c => c.charCodeAt(0)))
    this._result = buffer
    this._setReadyState(this.DONE)
  }
  fr.readAsDataURL(blob)
}
