/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateSpecificEvent = /* GraphQL */ `
  subscription OnUpdateSpecificEvent($id: ID) {
    onUpdateSpecificEvent(id: $id) {
      id
      owner
      name
      logo
      lat
      lng
      website
      needs
      currentNeedIndex
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      owner
      name
      logo
      lat
      lng
      website
      needs
      currentNeedIndex
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      owner
      name
      logo
      lat
      lng
      website
      needs
      currentNeedIndex
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      owner
      name
      logo
      lat
      lng
      website
      needs
      currentNeedIndex
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const onCreateGift = /* GraphQL */ `
  subscription OnCreateGift {
    onCreateGift {
      id
      owner
      eventID
      event {
        id
        owner
        name
        logo
        lat
        lng
        website
        needs
        currentNeedIndex
        gifts {
          nextToken
        }
      }
      donorID
      donorName
      donorMessage
      amount
    }
  }
`
export const onUpdateGift = /* GraphQL */ `
  subscription OnUpdateGift {
    onUpdateGift {
      id
      owner
      eventID
      event {
        id
        owner
        name
        logo
        lat
        lng
        website
        needs
        currentNeedIndex
        gifts {
          nextToken
        }
      }
      donorID
      donorName
      donorMessage
      amount
    }
  }
`
export const onDeleteGift = /* GraphQL */ `
  subscription OnDeleteGift {
    onDeleteGift {
      id
      owner
      eventID
      event {
        id
        owner
        name
        logo
        lat
        lng
        website
        needs
        currentNeedIndex
        gifts {
          nextToken
        }
      }
      donorID
      donorName
      donorMessage
      amount
    }
  }
`
export const onCreateDonor = /* GraphQL */ `
  subscription OnCreateDonor($owner: String!) {
    onCreateDonor(owner: $owner) {
      id
      owner
      firstName
      lastName
      email
      phone
      address
      tableNumber
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const onUpdateDonor = /* GraphQL */ `
  subscription OnUpdateDonor($owner: String!, $email: String!) {
    onUpdateDonor(owner: $owner, email: $email) {
      id
      owner
      firstName
      lastName
      email
      phone
      address
      tableNumber
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const onDeleteDonor = /* GraphQL */ `
  subscription OnDeleteDonor($owner: String!) {
    onDeleteDonor(owner: $owner) {
      id
      owner
      firstName
      lastName
      email
      phone
      address
      tableNumber
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const onCreateLabeledSensorData = /* GraphQL */ `
  subscription OnCreateLabeledSensorData($owner: String!) {
    onCreateLabeledSensorData(owner: $owner) {
      id
      owner
      alpha1
      beta1
      gamma1
      alpha2
      beta2
      gamma2
      alpha3
      beta3
      gamma3
      alpha4
      beta4
      gamma4
      alpha5
      beta5
      gamma5
      alpha6
      beta6
      gamma6
      alpha7
      beta7
      gamma7
      alpha8
      beta8
      gamma8
      alpha9
      beta9
      gamma9
      alpha10
      beta10
      gamma10
      label
    }
  }
`
export const onUpdateLabeledSensorData = /* GraphQL */ `
  subscription OnUpdateLabeledSensorData($owner: String!) {
    onUpdateLabeledSensorData(owner: $owner) {
      id
      owner
      alpha1
      beta1
      gamma1
      alpha2
      beta2
      gamma2
      alpha3
      beta3
      gamma3
      alpha4
      beta4
      gamma4
      alpha5
      beta5
      gamma5
      alpha6
      beta6
      gamma6
      alpha7
      beta7
      gamma7
      alpha8
      beta8
      gamma8
      alpha9
      beta9
      gamma9
      alpha10
      beta10
      gamma10
      label
    }
  }
`
export const onDeleteLabeledSensorData = /* GraphQL */ `
  subscription OnDeleteLabeledSensorData($owner: String!) {
    onDeleteLabeledSensorData(owner: $owner) {
      id
      owner
      alpha1
      beta1
      gamma1
      alpha2
      beta2
      gamma2
      alpha3
      beta3
      gamma3
      alpha4
      beta4
      gamma4
      alpha5
      beta5
      gamma5
      alpha6
      beta6
      gamma6
      alpha7
      beta7
      gamma7
      alpha8
      beta8
      gamma8
      alpha9
      beta9
      gamma9
      alpha10
      beta10
      gamma10
      label
    }
  }
`
