import React from 'react'
import * as WebBrowser from 'expo-web-browser'
import { StyleSheet, Image, TouchableOpacity } from 'react-native'
import { Appbar, Avatar } from 'react-native-paper'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from 'react-native-paper'
import AppbarImage from '../navigation/AppbarImage'
import { useUserContext } from '../contexts/UserContext'

const AppHeader = ({ scene, previous, navigation }) => {
  const theme = useTheme()
  const { options } = scene.descriptor
  const title = options.title !== undefined ? options.title : scene.route.name
  const website = options.website
  const { user } = useUserContext()

  return (
    <Appbar.Header theme={{ colors: { primary: theme.colors.surface } }}>
      {previous ? (
        <Appbar.BackAction
          onPress={navigation.goBack}
          color={theme.colors.primary}
        />
      ) : (
        <TouchableOpacity
          onPress={() => {
            navigation.openDrawer()
          }}>
          <Avatar.Image
            style={styles.avatar}
            size={40}
            source={
              user.selfie ||
              require('../../assets/images/missing-avatar-heart.png')
            }
          />
        </TouchableOpacity>
      )}
      <Appbar.Content title={previous ? title : <AppbarImage />} />
      {website && (
        <Appbar.Action
          icon="open-in-new"
          onPress={() => WebBrowser.openBrowserAsync(website)}
        />
      )}
    </Appbar.Header>
  )
}

const styles = StyleSheet.create({
  avatar: {
    marginLeft: 4
  }
})

export default AppHeader
