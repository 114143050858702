import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import RNPickerSelect from 'react-native-picker-select'
import { MaterialIcons } from '@expo/vector-icons'

import Amplify, { Storage, API, graphqlOperation } from 'aws-amplify'
import { listEvents } from '../graphql/queries'
import {
  onCreateEvent,
  onUpdateEvent,
  onDeleteEvent
} from '../graphql/subscriptions'

import { parseEventFromAPI } from '../utils/api'

import { useUserContext } from '../contexts/UserContext'

export default function EventSelector(props) {
  const { darkMode } = useUserContext()
  const styles = StyleSheet.create({
    inputIOS: {
      fontSize: 16,
      paddingVertical: 12,
      paddingHorizontal: 10,
      borderWidth: 1,
      borderColor: 'gray',
      borderRadius: 4,
      color: darkMode === 'dark' ? 'white' : 'black',
      paddingRight: 30, // to ensure the text is never behind the icon
      marginBottom: 20
    },
    inputAndroid: {
      fontSize: 16,
      paddingHorizontal: 10,
      paddingVertical: 8,
      borderWidth: 0.5,
      borderColor: 'gray',
      borderRadius: 8,
      color: darkMode === 'dark' ? 'white' : 'black',
      paddingRight: 30, // to ensure the text is never behind the icon
      marginBottom: 20
    }
  })

  const [pickerOptions, setPickerOptions] = useState([])
  const [picker, setPicker] = useState(null)

  // Read the current list of events
  useEffect(
    function loadPickerOptions() {
      API.graphql(graphqlOperation(listEvents, props.filter)).then(results => {
        setPickerOptions(
          results.data.listEvents.items.reduce((acc, item) => {
            acc.push({ label: item.name, value: parseEventFromAPI(item) })
            return acc
          }, [])
        )
      })
    },
    [setPickerOptions]
  )

  // Subscribe to create/delete/update
  useEffect(
    function subscribeToEventUpdates() {
      const subscription = API.graphql(
        graphqlOperation(onUpdateEvent)
      ).subscribe({
        next: response => {
          const updatedEvent = parseEventFromAPI(
            response.value.data.onUpdateEvent
          )
          setPickerOptions(
            pickerOptions.reduce((acc, item) => {
              acc.push(
                item.value.id == updatedEvent.id
                  ? { label: updatedEvent.name, value: updatedEvent }
                  : item
              )
              return acc
            }, [])
          )
        },
        error: err => console.log('ERROR onUpdateEvent', err)
      })
      return () => subscription.unsubscribe()
    },
    [pickerOptions, setPickerOptions]
  )

  const placeholder = { label: 'Choose event...', value: null }

  return (
    <View {...props}>
      <RNPickerSelect
        style={styles}
        ref={e => setPicker(e)}
        placeholder={placeholder}
        onValueChange={value => {
          picker.togglePicker()
          props.onValueChange(value)
        }}
        items={pickerOptions}
        Icon={() => {
          return (
            <MaterialIcons name="keyboard-arrow-down" size={50} color="#ccc" />
          )
        }}
      />
    </View>
  )
}

EventSelector.propTypes = {
  onValueChange: PropTypes.func,
  filter: PropTypes.object
}

EventSelector.defaultProps = {
  onValueChange: null,
  filter: null
}
