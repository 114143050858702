import React, { useState, useEffect } from 'react'
import { StyleSheet, View, Image, Vibration, Animated } from 'react-native'
import { Text, Button } from 'react-native-paper'
import { DeviceMotion } from 'expo-sensors'
import { predictGestureONNX } from '../utils/gesture-model-onnx'
import { SpinningImage } from '../components/SpinningImage'

export default function ConfirmGift({ navigation }) {
  const [raised, setRaised] = useState(false)

  useEffect(() => {
    DeviceMotion.setUpdateInterval(1000 / 100)
    const sample = []
    const sub = DeviceMotion.addListener(async data => {
      sample.push(data.rotation.alpha)
      sample.push(data.rotation.beta)
      sample.push(data.rotation.gamma)
      if (sample.length > 30) {
        sample.splice(0, 3)
      }

      if (sample.length == 30) {
        const preds = await predictGestureONNX(sample)
        if (preds[0] > preds[1]) {
          sub.remove()
          setRaised(true)
          const pattern =
            Platform.OS === 'android'
              ? [0, 1000, 500, 1000, 500, 1000, 500, 1000, 500, 500, 300, 500]
              : [0, 1500, 1500, 1500, 1000, 1000]
          Vibration.vibrate(pattern)
        }
      }
    })
    return () => {
      sub.remove()
      Vibration.cancel()
    }
  }, [raised, setRaised])

  return (
    <View style={styles.container}>
      <Image
        source={require('../../assets/images/pup-large.png')}
        style={styles.logo}
      />
      {!raised && (
        <Text style={styles.text}>
          Thank you for your support!{'\n\n'}
          To complete your gift, raise your phone and show it to your host.
        </Text>
      )}
      {raised && (
        <>
          <SpinningImage
            source={require('../../assets/images/orange-heart-lg.png')}></SpinningImage>
          <Button
            mode="contained"
            onPress={() => navigation.navigate('Event')}
            style={styles.doneButton}>
            Done
          </Button>
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff'
  },
  text: {
    padding: 10,
    textAlign: 'center'
  },
  logo: {
    width: '80%',
    resizeMode: 'contain'
  },
  doneButton: {
    marginTop: 50
  }
})
