import React, { useState, useEffect } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'
import { Button, FAB } from 'react-native-paper'

import EventSelector from '../components/EventSelector'

export default function Welcome({ navigation }) {
  const [fabOpen, setFabOpen] = useState(false)

  return (
    <View style={styles.container}>
      <EventSelector
        style={styles.eventChooser}
        onValueChange={value => {
          if (value) {
            navigation.navigate('Event', value)
          }
        }}
      />
      <FAB.Group
        open={fabOpen}
        icon="wrench"
        actions={[
          {
            icon: 'pencil',
            label: 'Admin',
            onPress: () => navigation.navigate('CRUD')
          },
          {
            icon: 'train',
            label: 'Train Model',
            onPress: () => navigation.navigate('Training')
          }
        ]}
        onStateChange={({ open }) => setFabOpen(open)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  eventChooser: {
    width: Dimensions.get('window').width - 64
  }
})
