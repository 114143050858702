import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Dimensions,
  StyleSheet,
  View,
  Image,
  ImageBackground
} from 'react-native'
import {
  Modal,
  Portal,
  Text,
  Button,
  TextInput,
  useTheme
} from 'react-native-paper'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import * as ImagePicker from 'expo-image-picker'
import Constants from 'expo-constants'
import * as Permissions from 'expo-permissions'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { Storage } from 'aws-amplify'

import { useImageMapContext } from '../contexts/ImageMapContext'
import { isLocalUri } from '../utils/uri'

export default function AddOrEditNeedModal(props) {
  const {
    needIndex,
    visible,
    setVisible,
    eventId,
    eventFormValues,
    updateNeeds
  } = props
  const theme = useTheme()
  const { resolveUris, imageUrlMap } = useImageMapContext()
  const { showActionSheetWithOptions } = useActionSheet()
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    watch,
    formState,
    reset
  } = useForm()

  useEffect(() => {
    //  Register fields for the form and setup validations
    register('image')
    register('name', {
      validate: input =>
        (input && input.length >= 5) ||
        'Need name must be at least 5 characters'
    })
    register('amount', {
      validate: input =>
        !input || !isNaN(parseInt(input)) || 'Amount must be an integer'
    })
    register('description')

    // If we got a needIndex then prepopulate our form
    if (needIndex != null) {
      const need = eventFormValues.needs[needIndex]
      setValue('image', need.image)
      setValue('name', need.name)
      setValue('description', need.description)
      setValue('amount', need.amount.toString())
    }
  }, [register, needIndex, eventFormValues, setValue])

  const formValues = watch()

  const IMAGE_WIDTH = Dimensions.get('window').width - 60
  const IMAGE_HEIGHT = (IMAGE_WIDTH * 3) / 4

  const styles = StyleSheet.create({
    modal: {
      flex: 1,
      justifyContent: 'flex-start',
      padding: 20
    },
    form: {
      backgroundColor: theme.colors.surface,
      padding: 10,
      borderRadius: 4
    },
    error: {
      fontSize: 12,
      color: theme.colors.accent
    },
    image: {
      width: IMAGE_WIDTH,
      height: IMAGE_HEIGHT,
      alignItems: 'center',
      flexDirection: 'column-reverse'
    },
    field: {
      marginBottom: 5,
      marginTop: 5
    },
    textArea: {
      height: 250,
      textAlignVertical: 'top'
    },
    buttons: {
      flexDirection: 'row-reverse',
      alignItems: 'flex-end'
    }
  })

  const dismiss = () => {
    reset({})
    setVisible(false)
  }

  const pickPhoto = () => {
    const options = ['Take new photo', 'Use photo library', 'Cancel']
    const cancelButtonIndex = 2

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex
      },
      buttonIndex => {
        if (buttonIndex === 0) {
          takePhotoWithCamera()
        } else if (buttonIndex === 1) {
          pickPhotoFromLibrary()
        }
      }
    )
  }

  const pickPhotoFromLibrary = async () => {
    const permissionResult = await ImagePicker.requestCameraRollPermissionsAsync()

    if (permissionResult.granted === false) {
      alert('Permission to access photo library is required!')
      return
    }

    const pickerResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true
    })

    if (pickerResult.cancelled != true) {
      setValue('image', pickerResult.uri)
    }
  }

  const takePhotoWithCamera = async () => {
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync()

    if (permissionResult.granted === false) {
      alert('Permission to access camera is required!')
      return
    }

    const pickerResult = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true
    })

    if (pickerResult.cancelled != true) {
      setValue('image', pickerResult.uri)
    }
  }

  const onSubmit = () => {
    ;(async function () {
      const fileName = `events/${eventId}/${formValues.image
        .split('/')
        .slice(-1)}`

      //  If it's a local file push it up into S3
      if (isLocalUri(formValues.image)) {
        const response = await fetch(formValues.image)
        const blob = await response.blob()
        const contentType = response.headers.get('Content-Type')

        // Put the image in S3 and wait for the reply so we can get a new URL
        await Storage.put(fileName, blob, { contentType })
      }

      // Save the need into our form
      if (needIndex) {
        //  We're editing a need... replace that item
        updateNeeds(
          eventFormValues.needs.map((need, index) => {
            return index == needIndex
              ? { ...formValues, image: fileName }
              : need
          })
        )
      } else {
        // New need created.
        updateNeeds(
          (eventFormValues.needs || []).concat([
            { ...formValues, image: fileName }
          ])
        )
      }

      dismiss()
    })()
  }

  const buildImageSource = image => {
    if (isLocalUri(image)) {
      return { uri: image }
    } else if (imageUrlMap[image]) {
      return { uri: imageUrlMap[image] }
    } else {
      return require('../../assets/images/blank-need.png')
    }
  }

  return (
    <Portal>
      <Modal
        theme={theme}
        visible={visible}
        onDismiss={dismiss}
        contentContainerStyle={styles.modal}>
        <KeyboardAwareScrollView
          resetScrollToCoords={{ x: 0, y: 0 }}
          contentContainerStyle={styles.form}
          scrollEnabled={true}>
          <ImageBackground
            resizeMode="cover"
            style={styles.image}
            source={buildImageSource(formValues.image)}>
            <Button
              style={{
                backgroundColor: theme.colors.surface,
                marginBottom: 20
              }}
              mode="outlined"
              icon="camera"
              onPress={pickPhoto}>
              {formValues.image ? 'Change' : 'Add'} Image
            </Button>
          </ImageBackground>
          {errors.image && (
            <Text style={styles.error}>{errors.image.message}</Text>
          )}
          <TextInput
            style={styles.field}
            label="Need Name"
            onChangeText={text => {
              setValue('name', text)
            }}
            returnKeyType="done"
            value={formValues.name}
          />
          {errors.name && (
            <Text style={styles.error}>{errors.name.message}</Text>
          )}
          <TextInput
            style={styles.field}
            label="Amount"
            onChangeText={text => {
              setValue('amount', text)
            }}
            keyboardType={'numbers-and-punctuation'}
            returnKeyType="done"
            value={formValues.amount}
          />
          {errors.amount && (
            <Text style={styles.error}>{errors.amount.message}</Text>
          )}
          <TextInput
            style={[styles.field, styles.textArea]}
            label="Need Description"
            onChangeText={text => {
              setValue('description', text)
            }}
            multiline={true}
            autoCompleteType="off"
            value={formValues.description}
          />
          {errors.description && (
            <Text style={styles.error}>{errors.description.message}</Text>
          )}
          <View style={styles.buttons}>
            <Button mode="contained" onPress={dismiss}>
              Cancel
            </Button>
            <Button
              style={{ marginRight: 8 }}
              mode="contained"
              disabled={!formState.dirty}
              onPress={handleSubmit(onSubmit)}>
              Done
            </Button>
          </View>
        </KeyboardAwareScrollView>
      </Modal>
    </Portal>
  )
}
