import React, { useState, useEffect } from 'react'
import {
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity
} from 'react-native'
import { Button, Paragraph, useTheme } from 'react-native-paper'
import { MaterialIcons } from '@expo/vector-icons'
import Amplify, { Storage, API, graphqlOperation } from 'aws-amplify'
import { onUpdateSpecificEvent } from '../graphql/subscriptions'
import { updateEvent } from '../graphql/mutations'

import { parseEventFromAPI } from '../utils/api'
import { formatCurrency } from '../utils/text-formatting'
import { useUserContext } from '../contexts/UserContext'

export default function Event({ navigation, route }) {
  const theme = useTheme()
  const { user } = useUserContext()
  const [eventId, setEventId] = useState(null)
  const [eventOwner, setEventOwner] = useState(null)
  const [eventWebsite, setEventWebsite] = useState(null)
  const [needIndex, setNeedIndex] = useState(0)
  const [needCount, setNeedCount] = useState(0)
  const [needTitle, setNeedTitle] = useState('Funding Need')
  const [needDescription, setNeedDescription] = useState(
    'Description of the funding need'
  )
  const [prevNeedAmount, setPrevNeedAmount] = useState(null)
  const [needAmount, setNeedAmount] = useState(null)
  const [nextNeedAmount, setNextNeedAmount] = useState(null)
  const [needImage, setNeedImage] = useState(null)

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center'
    },
    scroll: {
      flexGrow: 1,
      justifyContent: 'center'
    },
    needBackground: {
      backgroundColor: '#00000060',
      margin: 15,
      padding: 15
    },
    needTitle: {
      paddingBottom: 10,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 20,
      color: '#fff'
    },
    needDescription: {
      fontSize: 16,
      color: '#fff'
    },
    needImage: {
      width: '100%',
      height: '100%',
      flex: 1
    },
    needNav: {
      position: 'absolute',
      bottom: 20,
      width: 70,
      height: 70,
      backgroundColor: '#00000050',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    },
    needNavNext: {
      right: 0
    },
    needNavPrev: {
      left: 0
    },
    needNavText: {
      fontSize: 10,
      fontWeight: 'bold',
      color: '#fff'
    },
    giveLabel: {
      color: '#fff',
      fontSize: 12,
      textAlign: 'center'
    },
    giveAmount: {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: 30,
      textAlign: 'center'
    },
    giveButton: {
      backgroundColor: theme.colors.primary,
      width: 250,
      height: 150,
      borderRadius: 100,
      position: 'absolute',
      bottom: 20,
      flex: 1,
      justifyContent: 'center',
      shadowColor: '#00000050', // IOS
      shadowOffset: { height: 5, width: 5 }, // IOS
      shadowOpacity: 1, // IOS
      shadowRadius: 5, //IOS
      elevation: 2 // Android
    }
  })

  function initializeFromEvent(event) {
    ;(async () => {
      const prevNeed =
        event.currentNeedIndex > 0
          ? event.needs[event.currentNeedIndex - 1]
          : null
      const currentNeed = event.needs[event.currentNeedIndex]
      const nextNeed =
        event.currentNeedIndex < event.needs.length - 1
          ? event.needs[event.currentNeedIndex + 1]
          : null
      if (currentNeed) {
        const s3url = await Storage.get(currentNeed.image)
        setEventId(event.id)
        setEventOwner(event.owner)
        setEventWebsite(event.website)
        setNeedCount(event.needs.length)
        setNeedIndex(event.currentNeedIndex)
        setNeedTitle(currentNeed.name)
        setNeedDescription(currentNeed.description)
        setPrevNeedAmount(prevNeed ? prevNeed.amount : null)
        setNeedAmount(currentNeed.amount)
        setNextNeedAmount(nextNeed ? nextNeed.amount : null)
        setNeedImage({ uri: s3url })
      }
    })()
  }

  useEffect(() => {
    initializeFromEvent(route.params)
  }, [
    setEventId,
    setEventOwner,
    setEventWebsite,
    setNeedCount,
    setNeedIndex,
    setNeedTitle,
    setNeedDescription,
    setNeedAmount,
    setNeedImage
  ])

  // Subscribe to event updates
  useEffect(
    function subscribeToEventUpdates() {
      const subscription = API.graphql(
        graphqlOperation(onUpdateSpecificEvent, { id: eventId })
      ).subscribe({
        next: response => {
          initializeFromEvent(
            parseEventFromAPI(response.value.data.onUpdateSpecificEvent)
          )
        },
        error: err => console.log('ERROR onUpdateSpecificEvent', err)
      })
      return () => subscription.unsubscribe()
    },
    [
      eventId,
      setEventId,
      setEventOwner,
      setEventWebsite,
      setNeedCount,
      setNeedIndex,
      setNeedTitle,
      setNeedDescription,
      setNeedAmount,
      setNeedImage
    ]
  )

  async function changeNeed(increment) {
    await API.graphql(
      graphqlOperation(updateEvent, {
        input: {
          id: eventId,
          currentNeedIndex: (needIndex + increment) % needCount
        }
      })
    )
  }

  function renderNextPrev() {
    if (eventOwner == user.username) {
      return (
        <>
          {prevNeedAmount && (
            <TouchableOpacity
              style={[styles.needNav, styles.needNavPrev]}
              onPress={() => changeNeed(-1)}>
              <MaterialIcons
                name="keyboard-arrow-left"
                size={30}
                color="#fff"
              />
              <Text style={styles.needNavText}>
                {formatCurrency(prevNeedAmount)}
              </Text>
            </TouchableOpacity>
          )}
          {nextNeedAmount && (
            <TouchableOpacity
              style={[styles.needNav, styles.needNavNext]}
              onPress={() => changeNeed(1)}>
              <MaterialIcons
                name="keyboard-arrow-right"
                size={30}
                color="#fff"
              />
              <Text style={styles.needNavText}>
                {formatCurrency(nextNeedAmount)}
              </Text>
            </TouchableOpacity>
          )}
        </>
      )
    }
  }

  function renderGiveButton() {
    //  Only render Give button if the user doesn't own the event.
    if (eventOwner != user.username) {
      return (
        <TouchableOpacity
          style={styles.giveButton}
          onPress={() => navigation.navigate('ConfirmGift')}>
          <View>
            <Text style={styles.giveLabel}>Give</Text>
          </View>
          <View>
            <Text style={styles.giveAmount}>{formatCurrency(needAmount)}</Text>
          </View>
        </TouchableOpacity>
      )
    } else {
      return (
        <Text style={[styles.giveAmount, { top: -100 }]}>
          {formatCurrency(needAmount)}
        </Text>
      )
    }
  }

  return (
    <ImageBackground
      source={needImage}
      resizeMode="cover"
      style={styles.needImage}>
      <View style={styles.container}>
        <ScrollView contentContainerStyle={styles.scroll}>
          <View style={styles.needBackground}>
            <Text style={styles.needTitle}>{needTitle}</Text>
            <Text style={styles.needDescription}>{needDescription}</Text>
          </View>
        </ScrollView>
        {renderGiveButton()}
        {renderNextPrev()}
      </View>
    </ImageBackground>
  )
}
