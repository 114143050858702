import React from 'react'
import {
  Provider as PaperProvider,
  DefaultTheme,
  DarkTheme
} from 'react-native-paper'

import { useUserContext } from '../contexts/UserContext'

export default function UserThemedPaperProvider({ children }) {
  const { darkMode } = useUserContext()
  const theme =
    darkMode === 'light'
      ? {
          // See: https://github.com/callstack/react-native-paper/blob/master/src/styles/DefaultTheme.tsx for full config
          ...DefaultTheme,
          colors: {
            ...DefaultTheme.colors,
            primary: '#c05250', // usually your brand color
            accent: '#d67644', // complements the primary
            text: '#1a1919' // text color for content
            // background: "#f6f6f6"     // background color for pages, such as lists
            // surface: white            // background color for elements containing content, such as cards
            // disabled: "#00000026"     // color for disabled elements
            // placeholder: "#00000054"  // color for placeholder text, such as input placeholder
            // backdrop: "#00000050"     // color for backdrops of various components such as modals
            // notification: pinkA400    // color used in notices such as badges
            // error: "#B00020"          // color used for error messages
          },
          fonts: {
            //  See https://github.com/callstack/react-native-paper/blob/master/src/styles/fonts.tsx
            // regular: ...
            // medium: ...
            // thin: ...
            // light: ...
          }
        }
      : {
          ...DarkTheme,
          colors: {
            ...DarkTheme.colors,
            primary: '#c05250',
            accent: '#d67644'
          }
        }
  return <PaperProvider theme={theme}>{children}</PaperProvider>
}
