import { InferenceSession, Tensor } from 'onnxjs'
import * as FileSystem from 'expo-file-system'

const ALWAYS_FETCH_MODEL = false
const MODELS_CACHE_DIR = FileSystem.cacheDirectory + 'models/'
const modelCacheFile = filename => {
  return MODELS_CACHE_DIR + filename
}
const MODELS_S3_URL_BASE = 'https://s3.amazonaws.com/pup-models/'
const modelS3Uri = filename => {
  return MODELS_S3_URL_BASE + filename
}

let onnxSession = null

async function loadModel(filename) {
  if (onnxSession) return
  onnxSession = new InferenceSession({ backendHint: 'cpu' })

  // Check to see if our cached file exists
  const fileInfo = await FileSystem.getInfoAsync(modelCacheFile(filename))
  if (ALWAYS_FETCH_MODEL || !fileInfo.exists) {
    // Make sure our model dir exists
    const dirInfo = await FileSystem.getInfoAsync(MODELS_CACHE_DIR)
    if (!dirInfo.exists) {
      console.log('Creating models cache directory', MODELS_CACHE_DIR)
      await FileSystem.makeDirectoryAsync(MODELS_CACHE_DIR, {
        intermediates: true
      })
    }

    // Download the file
    console.log('Downloading model file', modelS3Uri(filename))
    await FileSystem.downloadAsync(
      modelS3Uri(filename),
      modelCacheFile(filename)
    )
  }

  console.log('Loading model', modelCacheFile(filename))
  const model = await onnxSession.loadModel(modelCacheFile(filename))
}

export const predictGestureONNX = async sample => {
  await loadModel('sensor-model-v1.onnx')
  const inputs = [new Tensor(new Float32Array(sample), 'float32', [1, 30])]
  const output = await onnxSession.run(inputs)
  const preds = output.values().next().value.data
  return preds
}
