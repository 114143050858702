import { withAuthenticator, AmplifyTheme } from 'aws-amplify-react-native'

const signUpConfig = { 
  hideAllDefaults: true,
  defaultCountryCode: '1',
  signUpFields: [
    {
      label: 'Name',
      key: 'name',
      required: true,
      displayOrder: 1,
      type: 'string'
    },
    {
      label: 'Email',
      key: 'username',
      required: true,
      displayOrder: 2,
      type: 'string'
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 3,
      type: 'password'
    }
  ]
}

// TODO: this feels ugly... better way to support theming the auth screens? might need to write our own components
const authTheme = {
  ...AmplifyTheme,
  button: Object.assign({}, AmplifyTheme.button, {
    backgroundColor: '#c05250'
  }),
  buttonDisabled: Object.assign({}, AmplifyTheme.buttonDisabled, {
    backgroundColor: '#c0525080'
  }),
  sectionFooterLink: Object.assign({}, AmplifyTheme.sectionFooterLink, {
    color: '#c05250'
  }),
  sectionHeaderText: Object.assign({}, AmplifyTheme.sectionHeaderText, {
    color: '#1a1919'
  })
}

export default function withThemedAuthenticator(component) {
  return withAuthenticator(component, false, [], null, authTheme, signUpConfig)
}
