import React from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import {
  DrawerContentComponentProps,
  DrawerContentOptions,
  DrawerContentScrollView,
  DrawerItem
} from '@react-navigation/drawer'
import {
  Avatar,
  Text,
  Title,
  Caption,
  Drawer,
  Switch,
  TouchableRipple,
  useTheme
} from 'react-native-paper'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useUserContext } from '../contexts/UserContext'

export default function DrawerContent(props) {
  const { signOut, user, darkMode, toggleDarkMode } = useUserContext()
  const paperTheme = useTheme()

  return (
    <DrawerContentScrollView {...props}>
      <View style={styles.userInfoSection}>
        <TouchableOpacity
          style={{ marginLeft: 10 }}
          onPress={() => {
            props.navigation.toggleDrawer()
          }}>
          <Avatar.Image
            source={
              user.selfie ||
              require('../../assets/images/missing-avatar-heart.png')
            }
            size={50}
          />
          <Title style={styles.title}>{user.name}</Title>
          <Caption style={styles.caption}>{user.email}</Caption>
        </TouchableOpacity>
      </View>
      <Drawer.Section style={styles.drawerSection}>
        <DrawerItem
          icon={({ color, size }) => (
            <MaterialCommunityIcons
              name="account-heart"
              color={color}
              size={size}
            />
          )}
          label="Profile"
          onPress={() => {
            props.navigation.navigate('EditProfile')
          }}
        />
        <DrawerItem
          icon={({ color, size }) => (
            <MaterialCommunityIcons
              name="credit-card"
              color={color}
              size={size}
            />
          )}
          label="Update Payment"
          onPress={() => {
            props.navigation.navigate('UpdatePayment')
          }}
        />
        <DrawerItem
          icon={({ color, size }) => (
            <MaterialCommunityIcons name="logout" color={color} size={size} />
          )}
          label="Sign Out"
          onPress={signOut}
        />
      </Drawer.Section>
      <Drawer.Section title="Preferences">
        <TouchableRipple onPress={toggleDarkMode}>
          <View style={styles.preference}>
            <Text>Dark Theme</Text>
            <View pointerEvents="none">
              <Switch value={darkMode === 'dark'} />
            </View>
          </View>
        </TouchableRipple>
      </Drawer.Section>
    </DrawerContentScrollView>
  )
}

const styles = StyleSheet.create({
  userInfoSection: {
    marginLeft: 8
  },
  drawerSection: {
    marginTop: 15
  },
  title: {
    marginTop: 20,
    fontWeight: 'bold'
  },
  caption: {
    fontSize: 14,
    lineHeight: 14
  },
  preference: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingHorizontal: 16
  }
})
