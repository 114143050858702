import React from 'react'
import { ImageBackground, StyleSheet, View } from 'react-native'
import { Text, Button } from 'react-native-paper'
import { Storage } from 'aws-amplify'
import * as ImagePicker from 'expo-image-picker'
import Constants from 'expo-constants'
import * as Permissions from 'expo-permissions'
import { useUserContext } from '../contexts/UserContext'

export default function EditProfile({ navigation }) {
  const { user, setUser } = useUserContext()

  const saveSelfieToS3Async = async () => {
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync()

    if (permissionResult.granted === false) {
      alert('Permission to access camera roll is required!')
      return
    }

    const pickerResult = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true
    })

    if (pickerResult.cancelled != true) {
      const response = await fetch(pickerResult.uri)
      const blob = await response.blob()
      const contentType = response.headers.get('Content-Type')

      // Put the image in S3 and wait for the reply so we can get a new URL
      await Storage.put(user.selfie.fileName, blob, { contentType })
      const s3url = await Storage.get(user.selfie.fileName)

      setUser({ ...user, selfie: { ...user.selfie, uri: s3url } })
    }
  }

  return (
    <ImageBackground
      source={user.selfie}
      resizeMode="cover"
      style={styles.selfie}>
      <View style={styles.container}>
        <Button mode="contained" onPress={saveSelfieToS3Async}>
          Update selfie
        </Button>
      </View>
    </ImageBackground>
  )
}

EditProfile.navigationOptions = {
  title: 'Edit Profile'
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  selfie: {
    width: '100%',
    height: '100%',
    flex: 1
  }
})
