import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo
} from 'react'
import { useColorScheme } from 'react-native-appearance'
import Amplify, { Auth, Storage, Analytics } from 'aws-amplify'

const Context = createContext()

export const UserProvider = ({ onStateChange, children }) => {
  const [user, setUser] = useState({})
  const colorScheme = useColorScheme()
  const [darkMode, setDarkMode] = useState(
    colorScheme === 'dark' ? 'dark' : 'light'
  )

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(authuser => {
      ;(async () => {
        // Fetch the selfie URL
        const fileName = `${authuser.username}.jpg`
        const s3url = await Storage.get(fileName)

        // Save user information
        setUser({
          email: authuser.attributes.email,
          name: authuser.attributes.name,
          username: authuser.username,
          selfie: { fileName, uri: s3url }
        })
      })()
    })
  }, [setUser])

  const signOut = () => {
    Auth.signOut()
      .then(() => {
        onStateChange('signedOut', null)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const toggleDarkMode = () => {
    setDarkMode(theme => (theme === 'light' ? 'dark' : 'light'))
  }

  const value = useMemo(
    () => ({
      darkMode,
      toggleDarkMode,
      signOut,
      user,
      setUser
    }),
    [darkMode, toggleDarkMode, signOut, user, setUser]
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const UserConsumer = Context.Consumer
export const useUserContext = () => useContext(Context)
