import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Animated, Easing } from 'react-native'

export function SpinningImage(props) {
  const spinValue = useRef(new Animated.Value(0)).current
  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg']
  })

  useEffect(() => {
    Animated.loop(
      Animated.timing(spinValue, {
        toValue: 1,
        duration: 2000,
        useNativeDriver: true,
        easing: Easing.linear
      })
    ).start(event => {
      if (event.finished) {
        spinValue.setValue(0)
      }
    })
  })

  return (
    <Animated.Image
      source={props.source}
      style={[
        props.style,
        {
          transform: [{ rotate: spin }]
        }
      ]}></Animated.Image>
  )
}
