/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:58b81162-1caa-4bab-bc7c-15fb9a3eaa4f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tvmLZ4g20",
    "aws_user_pools_web_client_id": "4e6e6c55fpumpj9c1a1uv42uhe",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://nixcmok3nfdyra4mlxfc4eq4te.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "assets145507-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
