/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      owner
      name
      logo
      lat
      lng
      website
      needs
      currentNeedIndex
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        logo
        lat
        lng
        website
        needs
        currentNeedIndex
        gifts {
          nextToken
        }
      }
      nextToken
    }
  }
`
export const getGift = /* GraphQL */ `
  query GetGift($id: ID!) {
    getGift(id: $id) {
      id
      owner
      eventID
      event {
        id
        owner
        name
        logo
        lat
        lng
        website
        needs
        currentNeedIndex
        gifts {
          nextToken
        }
      }
      donorID
      donorName
      donorMessage
      amount
    }
  }
`
export const listGifts = /* GraphQL */ `
  query ListGifts(
    $filter: ModelGiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGifts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        eventID
        event {
          id
          owner
          name
          logo
          lat
          lng
          website
          needs
          currentNeedIndex
        }
        donorID
        donorName
        donorMessage
        amount
      }
      nextToken
    }
  }
`
export const getDonor = /* GraphQL */ `
  query GetDonor($id: ID!) {
    getDonor(id: $id) {
      id
      owner
      firstName
      lastName
      email
      phone
      address
      tableNumber
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const listDonors = /* GraphQL */ `
  query ListDonors(
    $filter: ModelDonorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDonors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        firstName
        lastName
        email
        phone
        address
        tableNumber
        gifts {
          nextToken
        }
      }
      nextToken
    }
  }
`
export const getLabeledSensorData = /* GraphQL */ `
  query GetLabeledSensorData($id: ID!) {
    getLabeledSensorData(id: $id) {
      id
      owner
      alpha1
      beta1
      gamma1
      alpha2
      beta2
      gamma2
      alpha3
      beta3
      gamma3
      alpha4
      beta4
      gamma4
      alpha5
      beta5
      gamma5
      alpha6
      beta6
      gamma6
      alpha7
      beta7
      gamma7
      alpha8
      beta8
      gamma8
      alpha9
      beta9
      gamma9
      alpha10
      beta10
      gamma10
      label
    }
  }
`
export const listLabeledSensorDatas = /* GraphQL */ `
  query ListLabeledSensorDatas(
    $filter: ModelLabeledSensorDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabeledSensorDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        alpha1
        beta1
        gamma1
        alpha2
        beta2
        gamma2
        alpha3
        beta3
        gamma3
        alpha4
        beta4
        gamma4
        alpha5
        beta5
        gamma5
        alpha6
        beta6
        gamma6
        alpha7
        beta7
        gamma7
        alpha8
        beta8
        gamma8
        alpha9
        beta9
        gamma9
        alpha10
        beta10
        gamma10
        label
      }
      nextToken
    }
  }
`
