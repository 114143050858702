import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { Text, Button } from 'react-native-paper'

export default function CheckOut({ navigation }) {
  return (
    <View style={styles.container}>
      <Text>Check Out</Text>
      <Button
        mode="contained"
        onPress={() => {
          navigation.navigate('Results')
        }}>
        View results
      </Button>
    </View>
  )
}

CheckOut.navigationOptions = {
  title: 'Check Out'
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
