import './utils/polyfills'

import { registerRootComponent } from 'expo'
import { activateKeepAwake } from 'expo-keep-awake'
import React, { useEffect } from 'react'

import { SafeAreaProvider } from 'react-native-safe-area-context'
import { AppearanceProvider } from 'react-native-appearance'

import Amplify, { Analytics } from 'aws-amplify'
import config from '../aws-exports'

import withThemedAuthenticator from './authorization/withThemedAuthenticator'
import AppNavigator from './navigation/AppNavigator'
import { UserProvider } from './contexts/UserContext'
import { ImageMapProvider } from './contexts/ImageMapContext'
import UserThemedPaperProvider from './components/UserThemedPaperProvider'

//  NOTE we moved App.js into src because Amplify prefers code to be in ./src
//  See: https://docs.expo.io/versions/latest/sdk/register-root-component/
//  Code below was from node_modules/expo/AppEntry.js which was the previous "main" in package.json
if (__DEV__) {
  // Uncomment this to get a bunch more debugging output in the console.
  //window.LOG_LEVEL = "DEBUG";
  activateKeepAwake()
}

Amplify.configure(config)
Analytics.disable()

function App(props) {
  return (
    <SafeAreaProvider>
      <AppearanceProvider>
        <ImageMapProvider>
          <UserProvider onStateChange={props.onStateChange}>
            <UserThemedPaperProvider>
              <AppNavigator />
            </UserThemedPaperProvider>
          </UserProvider>
        </ImageMapProvider>
      </AppearanceProvider>
    </SafeAreaProvider>
  )
}

registerRootComponent(withThemedAuthenticator(App))
