import React from 'react'
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme
} from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { useTheme } from 'react-native-paper'

import { AppHeader } from '../navigation/AppHeader'
import MainStack from '../navigation/MainStack'
import DrawerContent from '../navigation/DrawerContent'

const Drawer = createDrawerNavigator()

export default function AppNavigator() {
  const theme = useTheme()

  return (
    <NavigationContainer theme={theme.dark ? DarkTheme : DefaultTheme}>
      <Drawer.Navigator drawerContent={props => <DrawerContent {...props} />}>
        <Drawer.Screen name="Root" component={MainStack}></Drawer.Screen>
      </Drawer.Navigator>
    </NavigationContainer>
  )
}
