export const formatCurrency = (
  amount,
  currency = 'USD',
  minimumFractionDigits = 0
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits
  })
  return formatter.format(amount)
}
