export const parseEventFromAPI = event => {
  if (event.needs) {
    event.needs = event.needs.map(need => {
      return JSON.parse(need)
    })
  } else {
    event.needs = []
  }

  if (!event.currentNeedIndex) {
    event.currentNeedIndex = 0
  }

  return event
}
