import React from 'react'
import { useTheme } from 'react-native-paper'
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs'
import color from 'color'

import EventCRUD from '../screens/EventCRUD'
import DonorCRUD from '../screens/DonorCRUD'

const Tab = createMaterialBottomTabNavigator()

export default function CRUD({ navigation }) {
  const theme = useTheme()

  return (
    <Tab.Navigator
      initialRoute="Events"
      shifting={false}
      activeColor={theme.colors.accent}
      inactiveColor={color(theme.colors.text).alpha(0.6).rgb().string()}
      sceneAnimationEnabled={false}
      barStyle={{ backgroundColor: theme.colors.surface }}>
      <Tab.Screen
        name="Events"
        component={EventCRUD}
        options={{ tabBarIcon: 'calendar-heart' }}
      />
      <Tab.Screen
        name="Donors"
        component={DonorCRUD}
        options={{ tabBarIcon: 'account-heart' }}
      />
    </Tab.Navigator>
  )
}
