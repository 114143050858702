/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      owner
      name
      logo
      lat
      lng
      website
      needs
      currentNeedIndex
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      owner
      name
      logo
      lat
      lng
      website
      needs
      currentNeedIndex
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      owner
      name
      logo
      lat
      lng
      website
      needs
      currentNeedIndex
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const createGift = /* GraphQL */ `
  mutation CreateGift(
    $input: CreateGiftInput!
    $condition: ModelGiftConditionInput
  ) {
    createGift(input: $input, condition: $condition) {
      id
      owner
      eventID
      event {
        id
        owner
        name
        logo
        lat
        lng
        website
        needs
        currentNeedIndex
        gifts {
          nextToken
        }
      }
      donorID
      donorName
      donorMessage
      amount
    }
  }
`
export const updateGift = /* GraphQL */ `
  mutation UpdateGift(
    $input: UpdateGiftInput!
    $condition: ModelGiftConditionInput
  ) {
    updateGift(input: $input, condition: $condition) {
      id
      owner
      eventID
      event {
        id
        owner
        name
        logo
        lat
        lng
        website
        needs
        currentNeedIndex
        gifts {
          nextToken
        }
      }
      donorID
      donorName
      donorMessage
      amount
    }
  }
`
export const deleteGift = /* GraphQL */ `
  mutation DeleteGift(
    $input: DeleteGiftInput!
    $condition: ModelGiftConditionInput
  ) {
    deleteGift(input: $input, condition: $condition) {
      id
      owner
      eventID
      event {
        id
        owner
        name
        logo
        lat
        lng
        website
        needs
        currentNeedIndex
        gifts {
          nextToken
        }
      }
      donorID
      donorName
      donorMessage
      amount
    }
  }
`
export const createDonor = /* GraphQL */ `
  mutation CreateDonor(
    $input: CreateDonorInput!
    $condition: ModelDonorConditionInput
  ) {
    createDonor(input: $input, condition: $condition) {
      id
      owner
      firstName
      lastName
      email
      phone
      address
      tableNumber
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const updateDonor = /* GraphQL */ `
  mutation UpdateDonor(
    $input: UpdateDonorInput!
    $condition: ModelDonorConditionInput
  ) {
    updateDonor(input: $input, condition: $condition) {
      id
      owner
      firstName
      lastName
      email
      phone
      address
      tableNumber
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const deleteDonor = /* GraphQL */ `
  mutation DeleteDonor(
    $input: DeleteDonorInput!
    $condition: ModelDonorConditionInput
  ) {
    deleteDonor(input: $input, condition: $condition) {
      id
      owner
      firstName
      lastName
      email
      phone
      address
      tableNumber
      gifts {
        items {
          id
          owner
          eventID
          donorID
          donorName
          donorMessage
          amount
        }
        nextToken
      }
    }
  }
`
export const createLabeledSensorData = /* GraphQL */ `
  mutation CreateLabeledSensorData(
    $input: CreateLabeledSensorDataInput!
    $condition: ModelLabeledSensorDataConditionInput
  ) {
    createLabeledSensorData(input: $input, condition: $condition) {
      id
      owner
      alpha1
      beta1
      gamma1
      alpha2
      beta2
      gamma2
      alpha3
      beta3
      gamma3
      alpha4
      beta4
      gamma4
      alpha5
      beta5
      gamma5
      alpha6
      beta6
      gamma6
      alpha7
      beta7
      gamma7
      alpha8
      beta8
      gamma8
      alpha9
      beta9
      gamma9
      alpha10
      beta10
      gamma10
      label
    }
  }
`
export const updateLabeledSensorData = /* GraphQL */ `
  mutation UpdateLabeledSensorData(
    $input: UpdateLabeledSensorDataInput!
    $condition: ModelLabeledSensorDataConditionInput
  ) {
    updateLabeledSensorData(input: $input, condition: $condition) {
      id
      owner
      alpha1
      beta1
      gamma1
      alpha2
      beta2
      gamma2
      alpha3
      beta3
      gamma3
      alpha4
      beta4
      gamma4
      alpha5
      beta5
      gamma5
      alpha6
      beta6
      gamma6
      alpha7
      beta7
      gamma7
      alpha8
      beta8
      gamma8
      alpha9
      beta9
      gamma9
      alpha10
      beta10
      gamma10
      label
    }
  }
`
export const deleteLabeledSensorData = /* GraphQL */ `
  mutation DeleteLabeledSensorData(
    $input: DeleteLabeledSensorDataInput!
    $condition: ModelLabeledSensorDataConditionInput
  ) {
    deleteLabeledSensorData(input: $input, condition: $condition) {
      id
      owner
      alpha1
      beta1
      gamma1
      alpha2
      beta2
      gamma2
      alpha3
      beta3
      gamma3
      alpha4
      beta4
      gamma4
      alpha5
      beta5
      gamma5
      alpha6
      beta6
      gamma6
      alpha7
      beta7
      gamma7
      alpha8
      beta8
      gamma8
      alpha9
      beta9
      gamma9
      alpha10
      beta10
      gamma10
      label
    }
  }
`
