import React, { createContext, useContext, useState, useMemo } from 'react'

import Amplify, { Storage } from 'aws-amplify'

const Context = createContext()

export const ImageMapProvider = ({ children }) => {
  const [imageUrlMap, setImageUrlMap] = useState({})

  const resolveUris = images => {
    const toResolve = images.filter(
      image => typeof imageUrlMap[image] === 'undefined'
    )

    //  Lookup all of our URIs in batch
    if (toResolve.length > 0) {
      //  Initialize our map entry to null
      setImageUrlMap(
        toResolve.reduce((map, image) => {
          return { ...map, [image]: null }
        }, imageUrlMap)
      )

      //  Fetch URIs and add them to our map
      const gets = toResolve.map(image => Storage.get(image))
      Promise.all(gets).then(s3urls => {
        setImageUrlMap(
          s3urls.reduce((map, url, index) => {
            return {
              ...map,
              [toResolve[index]]: url
            }
          }, imageUrlMap)
        )
      })
    }
  }

  const value = useMemo(() => ({ resolveUris, imageUrlMap }), [resolveUris])
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const ImageMapConsumer = Context.Consumer
export const useImageMapContext = () => useContext(Context)
